export default {
  computed: {
    getStatusColor(){
      return (status) => status ? "primary" : "warning"
    },
  },
  methods: {
    absoluteUrl(path = ""){
      return this.$store.state.domain + path
    },
    isImageFile(ext){
      return ['jpeg','jpg','svg','png','webp','gif'].includes(ext)
    },
    preText(content) {
      if(!content) return;
      const words = content.replace(/\n/g, ' <br>').split(' ');
      const formattedWords = [];
      words.forEach(word => {
        if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
          formattedWords.push(`<a href="${word}" class="text-success" target="_blank"> ${word} </a>`);
        } else {
          formattedWords.push(word)
        }
      });
      return formattedWords.join(' ')
    },
    reviewFormart(reviews){
      if(!reviews){ reviews = []}
      let total = reviews.length
      let five = reviews.filter(r => r.rating == 5).length
      let four = reviews.filter(r => r.rating == 4).length
      let three = reviews.filter(r => r.rating == 3).length
      let two = reviews.filter(r => r.rating == 2).length
      let one = reviews.filter(r => r.rating == 1).length
      let average = total ? Math.round(((five*5+four*4+three*3+two*2+one*1)/total) * 10)/10 : 0;
      return `${average} (${total})`;
    },
    calculateDeliveryTime(time){
      if(!time){return}
      return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
    },
    scrollToReview(elID){
        document.getElementById(elID).scrollIntoView({behavior: "smooth"})
    },
    alertSuccess(text){
      this.$vs.notify({
      title:'Success',
      text:text,
      color:'success',
      iconPack: 'feather',
      icon:'icon-check',
      position:'top-right'})
    },
    alertError(text){
      this.$vs.notify({
      title:'Error',
      text:text,
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'top-right'})
    },
  }
};
